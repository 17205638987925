import isBoolean from 'lodash/isBoolean';
import omit from 'lodash/omit';
import ad from 'src/common/constants/analytics/categoryActionLabel/ad';
import { reportingAdFormats } from 'src/common/constants/reporting/adDisplayFormats';
import {
  adSlotNameMap,
  reportingAdSlotNames,
} from 'src/common/constants/reporting/adSlot';
import { adTypes, adTypesMap } from 'src/common/constants/reporting/adTypes';
import { reportingInstreamAdQuartileNames } from 'src/common/constants/reporting/instreamAdQuartileEvents';
import { unifiedEvents } from '../../client/api';
import { mintSingleton } from '../../client/mint';
import { tunerSingleton } from '../../client/tuner';
import { MINT_ERROR, MINT_INFO } from '../constants/ads/logging';
import { playerStatuses } from '../constants/playerStatuses';
import { amazonVideoKeywords } from '../constants/reporting/sandboxCategoryActionProps';
import { getSliderPercentage } from '../utils/getSliderPercentage';
import { formatDimensions } from '../utils/reporting';
import {
  hideVideoAdPlayer,
  openNowPlayingDialog,
  setLearnMoreUrl,
  showVideoAdPlayer,
  updateVideoAdFormat,
} from './dialog';
import {
  logCategoryActionLabel,
  logClientError,
  logClientInfo,
} from './logging';
import { displaySlotLoaded } from './mint';
import {
  handlePause,
  mediaAdEnded,
  mediaAdError,
  mediaAdLoaded,
  mediaAdPlaying,
} from './player';
import { positionChanged } from './tuner';

function transformAdData(adInfo) {
  const {
    adUnitId = '',
    adNetworkName = '',
    adRequestId = '',
    bitrate = 0,
    currentAdPosition,
    isVideoAd,
    creativeId,
    totalAdsInPod,
    slotName,
    wasAdSkipped,
  } = adInfo;

  return {
    adCreativeId: creativeId || '',
    adType: isVideoAd ? adTypes.AD_TYPE_VIDEO : adTypes.AD_TYPE_AUDIO,
    currentVideoaudiorollIdx: currentAdPosition || 0,
    noOfVideoaudiorollsReceived: totalAdsInPod || 0,
    adSlot: adSlotNameMap[slotName] || reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
    ...(isBoolean(wasAdSkipped) && { wasAdSkipped }),
    adNetworkName,
    adUnitId,
    adRequestId,
    bitrate,
  };
}

export default function mintEventDispatcher(dispatch) {
  return () => {
    function onEligibilityDecided(adInfo = {}) {
      const { adType, slotName, isEligible, isPlatformEligible } = adInfo;

      unifiedEvents.reportAdsVideoAudioRollEligibilityDecided({
        isEligible,
        isPlatformEligible,
        adType: adTypesMap[adType] || adTypes.AD_TYPE_UNSPECIFIED,
        adSlot:
          adSlotNameMap[slotName] || reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
      });
    }

    function onRequested(adInfo = {}) {
      const {
        adType,
        adUnitId = '',
        slotName,
        adNetworkName = '',
        adRequestId,
        adRequestHasAmazonKeywords = false,
      } = adInfo;
      unifiedEvents.reportAdsVideoAudioRollRequested({
        adSlot:
          adSlotNameMap[slotName] || reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
        adNetworkName,
        adRequestId,
        adType: adTypesMap[adType] || adTypes.AD_TYPE_UNSPECIFIED,
        adRequestHasAmazonKeywords,
        adUnitId,
      });
    }

    function onAmazonKeywordsRequestFailed(errorInfo = {}) {
      const { errorCode, errorDescription } = errorInfo;

      unifiedEvents.reportSandbox({
        category: amazonVideoKeywords.category,
        action: amazonVideoKeywords.actions.error,
        props: {
          AD_TYPE: adTypes.AD_TYPE_VIDEO,
          ErrorCode: errorCode,
          ErrorDescription: errorDescription,
        },
      });
    }

    function onLoaded(adInfo = {}) {
      const {
        height,
        width,
        isVideoAd,
        slotName,
        bitrate,
        adNetworkName = '',
        adRequestId,
        totalAdsInPod,
        currentAdPosition,
      } = adInfo;
      tunerSingleton.instance?.pause();

      // For a case with multiple video ads in a row,
      // when next loaded ad is not of video type let's hide the player
      if (!isVideoAd) {
        dispatch(hideVideoAdPlayer());
      }

      if (height && width) {
        dispatch(updateVideoAdFormat(width, height));
      }

      if (currentAdPosition === 1) {
        unifiedEvents.reportAdsVideoAudioRollResponseReceived({
          adSlot:
            adSlotNameMap[slotName] || reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
          noOfVideoaudiorollsReceived: totalAdsInPod,
          adType: isVideoAd ? adTypesMap.video : adTypesMap.audio,
          bitrate,
          adNetworkName,
          adRequestId,
        });
      }

      dispatch(mediaAdLoaded(slotName));
    }

    function onPlaying(adInfo = {}) {
      unifiedEvents.reportAdsPlaybackStarted(transformAdData(adInfo));
      dispatch(mediaAdPlaying());
      dispatch(openNowPlayingDialog());
    }

    function onProgress(timeData = {}) {
      const { duration, position: elapsedSeconds } = timeData;
      const elapsedPercent = getSliderPercentage(elapsedSeconds, duration);

      // this fires on every progress update, but is necessary to avoid scenarios where the content player is
      // inadvertently resumed. e.g., when iOS Safari tries to resume due to user tab-switching, etc.
      if (tunerSingleton.instance?.state.name !== playerStatuses.paused) {
        tunerSingleton.instance?.pause();
      }

      dispatch(
        positionChanged({
          duration,
          elapsedSeconds,
          elapsedPercent,
        }),
      );
    }

    function onPaused() {
      dispatch(handlePause());
    }

    function onAllEnded({
      slotName,
      adsCount,
      adRequestId,
      adNetworkName = '',
    }) {
      dispatch(hideVideoAdPlayer());
      dispatch(mediaAdEnded());

      if (adsCount === 0) {
        unifiedEvents.reportAdsVideoAudioRollResponseReceived({
          adSlot:
            adSlotNameMap[slotName] || reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
          adType: adTypes.AD_TYPE_UNSPECIFIED,
          noOfVideoaudiorollsReceived: adsCount,
          adNetworkName,
          adRequestId,
          bitrate: 0,
        });
      }

      dispatch(
        logCategoryActionLabel({
          category: ad.category,
          action: ad.actions[`${slotName}Request`],
          label: `${ad.labels.response}.${adsCount}`,
        }),
      );
    }

    function onEnded(adInfo) {
      unifiedEvents.reportAdsPlaybackFinished(
        omit(
          transformAdData({
            ...adInfo,
            wasAdSkipped: adInfo?.wasAdSkipped || false,
          }),
          'bitrate',
        ),
      );
    }

    function onAudioVideoError(errorData = {}) {
      const { errorCode, errorMessage, adInfo, errorType } = errorData;

      if (
        [
          mintSingleton.instance?.Events.ErrorTypes.AD_PLAY,
          mintSingleton.instance?.Events.ErrorTypes.AD_PLAY_START_TIMEOUT,
        ].includes(errorType)
      ) {
        unifiedEvents.reportAdsPlaybackFailed({
          ...transformAdData(adInfo),
          errorCode: errorCode?.toString() || '',
          errorMessage: errorMessage || '',
          debugDescription: 'Audio Video Ad, Playback Failed',
        });
      } else if (
        [
          mintSingleton.instance?.Events.ErrorTypes.AD_LOAD,
          mintSingleton.instance?.Events.ErrorTypes.AD_NO_FILL,
        ].includes(errorType)
      ) {
        unifiedEvents.reportAdsVideoAudioRollRequestFailed({
          adType: adTypesMap[adInfo?.adType] || adTypes.AD_TYPE_UNSPECIFIED,
          adSlot:
            adSlotNameMap[adInfo?.slotName] ||
            reportingAdSlotNames.AD_SLOT_UNSPECIFIED,
          adRequestId: adInfo?.adRequestId || '',
          errorCode: errorCode?.toString() || '',
          errorMessage: errorMessage || errorType || '',
          debugDescription: 'Audio Video Ad, Request Failed',
        });
      }

      dispatch(hideVideoAdPlayer());
      dispatch(mediaAdError());
    }

    function onVideoStarted() {
      dispatch(showVideoAdPlayer());
    }

    function onSkipped(adEvent) {
      if (adEvent.adPosition === adEvent.totalAdsInPod) {
        dispatch(hideVideoAdPlayer());
      }
    }

    function onVideoSkipped(adInfo) {
      onEnded({
        ...adInfo,
        wasAdSkipped: true,
      });
    }

    function onSetLearnMore(url) {
      dispatch(setLearnMoreUrl(url));
    }

    function onMintError(event) {
      const data = {
        message: MINT_ERROR,
        context: event,
      };
      dispatch(logClientError(data));
    }

    function onMintInfo(info) {
      const data = {
        message: MINT_INFO,
        context: { info },
      };
      dispatch(logClientInfo(data));
    }

    function onDisplayAdPaused(adSizeString) {
      dispatch(
        logCategoryActionLabel({
          category: ad.category,
          action: adSizeString,
          label: ad.labels.pause,
        }),
      );
    }

    function onDisplayAdResumed(adSizeString) {
      dispatch(
        logCategoryActionLabel({
          category: ad.category,
          action: adSizeString,
          label: ad.labels.resume,
        }),
      );
    }

    function onDisplayAdRequested({ isCompanionAd, slot = {} }) {
      const { formatDefinition = {}, requestId = '' } = slot;
      const {
        adProvider = '',
        adUnitId = '',
        dimensions = [],
      } = formatDefinition || {};
      const formattedDimensions = formatDimensions(dimensions, true);

      unifiedEvents.reportAdsDisplayRequested({
        adSlot: reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adDisplayFormat:
          formattedDimensions.length === 1
            ? formattedDimensions[0]
            : reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED,
        adDisplayFormatsAccepted: formattedDimensions || [
          reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED,
        ],
        adRequestId: requestId,
        adNetworkName: adProvider,
        adUnitId,
        isCompanionAd,
      });
    }

    function onDisplayAdResponse({ isCompanionAd = false, slot = {} }) {
      const { formatDefinition = {}, requestId = '', creativeId } = slot;
      const { adProvider = '', adUnitId = '' } = formatDefinition || {};

      unifiedEvents.reportAdsDisplayResponseReceived({
        adSlot: reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adDisplayFormat: reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED, // NOTE: The responseReceived event from Google does not expose the size of the ad
        adRequestId: requestId,
        adNetworkName: adProvider,
        adCreativeId: creativeId?.toString() || '',
        adUnitId,
        isCompanionAd,
      });
    }

    function onDisplayAdFailed({
      debugDescription,
      errorCode,
      errorMessage,
      slot = {},
    }) {
      const { formatDefinition = {}, requestId = '' } = slot;
      const { adProvider = '', adUnitId = '' } = formatDefinition || {};

      unifiedEvents.reportAdsDisplayRequestFailed({
        adSlot: reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adRequestId: requestId,
        adDisplayFormat: reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED,
        adNetworkName: adProvider,
        adUnitId,
        errorMessage,
        errorCode,
        debugDescription,
      });
    }

    function onDisplayAdLoaded({ isCompanionAd, isInstream, slot = {} }) {
      const {
        creativeId,
        currentDimensions = [],
        elementId,
        requestId = '',
        formatDefinition = {},
      } = slot;
      const { adProvider = '', adUnitId = '' } = formatDefinition || {};
      const formattedDimensions = formatDimensions(currentDimensions);

      dispatch(displaySlotLoaded(elementId));

      unifiedEvents.reportAdsDisplayImpression({
        adSlot: isInstream
          ? reportingAdSlotNames.AD_SLOT_INSTREAM
          : reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adDisplayFormat:
          formattedDimensions ||
          reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED,
        adCreativeId: creativeId?.toString() || '',
        adRequestId: requestId,
        adNetworkName: adProvider || '',
        adUnitId,
        isCompanionAd,
      });
    }

    function onDisplayAdViewable({
      isCompanionAd,
      isInstream,
      isViewable,
      slot = {},
    }) {
      const {
        creativeId,
        currentDimensions = [],
        formatDefinition = {},
      } = slot;
      const formattedDimensions = formatDimensions(currentDimensions);

      unifiedEvents.reportAdsDisplayViewabilityStatus({
        adSlot: isInstream
          ? reportingAdSlotNames.AD_SLOT_INSTREAM
          : reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adDisplayFormat:
          formattedDimensions ||
          reportingAdFormats.AD_DISPLAY_FORMAT_UNSPECIFIED,
        adCreativeId: creativeId?.toString() || '',
        adNetworkName: formatDefinition?.adProvider || '',
        isCompanionAd,
        isViewable,
      });
    }

    function onDisplayAdClicked({ isCompanionAd, isInstream, slot = {} }) {
      const {
        creativeId,
        currentDimensions = [],
        formatDefinition = {},
      } = slot;
      const formattedDimensions = formatDimensions(currentDimensions);

      unifiedEvents.reportAdsDisplayClicked({
        adSlot: isInstream
          ? reportingAdSlotNames.AD_SLOT_INSTREAM
          : reportingAdSlotNames.AD_SLOT_DISPLAY,
        adType: adTypes.AD_TYPE_DISPLAY,
        adDisplayFormat: formattedDimensions,
        adCreativeId: creativeId?.toString() || '',
        adNetworkName: formatDefinition?.adProvider || '',
        destinationUrl: '',
        isCompanionAd,
      });
    }

    // when mint finishes with the last ad, it emits an event to tell the
    // tuner to start polling again for more ad data
    function onInstreamAdRequest() {
      tunerSingleton.instance?.requestInstreamAds(); // send message to web-tuner
    }
    function onInstreamAdReceived({ ad: adData }) {
      const { adProvider = '', adUnitId = '' } = adData || {};

      unifiedEvents.reportAdsInstreamReceived({
        adSlot: reportingAdSlotNames.AD_SLOT_INSTREAM,
        adType: adTypes.AD_TYPE_AUDIO,
        adNetworkName: adProvider || '',
        adUnitEventId: '',
        adUnitId,
      });
    }

    function onInstreamAdStarted({ ad: adData, eventId: adUnitEventId }) {
      const {
        adProvider = '',
        adUnitId = '',
        durationInSeconds,
      } = adData || {};

      unifiedEvents.reportAdsInstreamStarted({
        adSlot: reportingAdSlotNames.AD_SLOT_INSTREAM,
        adType: adTypes.AD_TYPE_AUDIO,
        adNetworkName: adProvider || '',
        duration: durationInSeconds,
        adUnitEventId,
        adUnitId,
      });
    }

    function onInstreamAdQuartile({
      adData,
      eventId: adUnitEventId,
      quartile,
    }) {
      const { adProvider = '', adUnitId = '' } = adData || {};

      unifiedEvents.reportAdsInstreamQuartileStatus({
        adSlot: reportingAdSlotNames.AD_SLOT_INSTREAM,
        adType: adTypes.AD_TYPE_AUDIO,
        adNetworkName: adProvider || '',
        adUnitId,
        adUnitEventId,
        quartile,
      });
    }

    function onInstreamAdCompleted({ ad: adData, eventId: adUnitEventId }) {
      const { adProvider = '', adUnitId = '' } = adData || {};

      unifiedEvents.reportAdsInstreamCompleted({
        adSlot: reportingAdSlotNames.AD_SLOT_INSTREAM,
        adType: adTypes.AD_TYPE_AUDIO,
        adNetworkName: adProvider || '',
        adUnitId,
        adUnitEventId,
      });
    }

    // before trying to play the stream, the instream player has to make a
    // post request with the url and pass it these params (from mint)
    function onInstreamUpdateParams(custParamsPromise) {
      tunerSingleton.instance?.setInstreamCustParams(custParamsPromise); // send message to web-tuner
    }

    let mintEventsAndCallbacks;

    function generateEventsAndCallbacks() {
      const {
        Type: { audioVideoAd, instream } = {},
        Media: { video, display } = {},
        Logging: { info, error } = {},
        Status: {
          eligibilityDecided,
          amazonKeywordsRequestFailed,
          requested,
          response,
          failed,
          loaded,
          viewable,
          click,
          playing,
          skipped,
          paused,
          started,
          ended,
          firstQuartile,
          midPoint,
          thirdQuartile,
          allEnded,
          progress,
          setLearnMore,
          resumed,
          requestInstreamAds,
          updateInstreamParams,
        } = {},
      } = mintSingleton.instance?.Events || {};

      mintEventsAndCallbacks = [
        // Audio/Video Events
        {
          events: [audioVideoAd, eligibilityDecided],
          callback: onEligibilityDecided,
        },
        {
          events: [audioVideoAd, amazonKeywordsRequestFailed],
          callback: onAmazonKeywordsRequestFailed,
        },
        { events: [audioVideoAd, requested], callback: onRequested },
        { events: [audioVideoAd, loaded], callback: onLoaded },
        { events: [audioVideoAd, playing], callback: onPlaying },
        { events: [audioVideoAd, skipped], callback: onSkipped },
        { events: [audioVideoAd, progress], callback: onProgress },
        { events: [audioVideoAd, paused], callback: onPaused },
        { events: [audioVideoAd, allEnded], callback: onAllEnded },
        { events: [audioVideoAd, error], callback: onAudioVideoError },
        { events: [audioVideoAd, ended], callback: onEnded },

        // Video Events
        { events: [audioVideoAd, video, started], callback: onVideoStarted },
        { events: [audioVideoAd, video, skipped], callback: onVideoSkipped },
        {
          events: [audioVideoAd, video, setLearnMore],
          callback: onSetLearnMore,
        },

        // Mint Events
        { events: [info], callback: onMintInfo },
        { events: [error], callback: onMintError },

        // Instream Events
        {
          events: [instream, requestInstreamAds],
          callback: onInstreamAdRequest,
        },
        {
          events: [instream, updateInstreamParams],
          callback: onInstreamUpdateParams,
        },
        {
          events: [instream, response],
          callback: onInstreamAdReceived, // AdsInstreamReceivedEvent
        },
        {
          events: [instream, started],
          callback: onInstreamAdStarted, // AdsInstreamStartedEvent
        },
        {
          events: [instream, firstQuartile],
          callback: ({ ad: adData, eventId }) =>
            onInstreamAdQuartile({
              adData,
              eventId,
              quartile: reportingInstreamAdQuartileNames.firstQuartile,
            }), // AdsInstreamQuartileStatusEvent
        },
        {
          events: [instream, midPoint],
          callback: ({ ad: adData, eventId }) =>
            onInstreamAdQuartile({
              adData,
              eventId,
              quartile: reportingInstreamAdQuartileNames.midPoint,
            }), // AdsInstreamQuartileStatusEvent
        },
        {
          events: [instream, thirdQuartile],
          callback: ({ ad: adData, eventId }) =>
            onInstreamAdQuartile({
              adData,
              eventId,
              quartile: reportingInstreamAdQuartileNames.thirdQuartile,
            }), // AdsInstreamQuartileStatusEvent
        },
        {
          events: [instream, ended],
          callback: onInstreamAdCompleted, // AdsInstreamCompletedEvent
        },

        // Display Ad Events
        { events: [display, requested], callback: onDisplayAdRequested }, // AdsDisplayRequestedEvent
        { events: [display, response], callback: onDisplayAdResponse }, // AdsDisplayResponseReceivedEvent
        { events: [display, failed], callback: onDisplayAdFailed }, // AdsDisplayRequestFailedEvent
        { events: [display, loaded], callback: onDisplayAdLoaded }, // AdsDisplayImpressionEvent
        { events: [display, viewable], callback: onDisplayAdViewable }, // AdsDisplayViewabilityStatusEvent
        { events: [display, click], callback: onDisplayAdClicked }, // AdsDisplayClickedEvent
        { events: [display, paused], callback: onDisplayAdPaused },
        { events: [display, resumed], callback: onDisplayAdResumed },
      ];
    }

    function handleEventListenerRegistration(action) {
      mintEventsAndCallbacks.forEach(({ events, callback }) => {
        mintSingleton.instance?.event(...events)[action](callback);
      });
    }

    function addListeners() {
      handleEventListenerRegistration('listen');
    }

    function removeListeners() {
      handleEventListenerRegistration('remove');
    }

    async function init() {
      generateEventsAndCallbacks();
      addListeners();
    }

    init();
    return removeListeners;
  };
}
