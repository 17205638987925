import { reportingAdSlotNames } from '../../common/constants/reporting/adSlot';
import { adTypes } from '../../common/constants/reporting/adTypes';
import {
  app,
  player,
  playerClick,
} from '../../common/constants/reporting/sandboxCategoryActionProps';
import { selectDiscordState, selectIsFord } from '../../common/selectors/app';
import { safeJsonStringify } from '../../common/utils/object/safeJsonStringify';
import { getContentEventData } from '../../common/utils/reporting/containerItems';
import { storeSingleton } from '../store/storeSingleton';
import { apiSingleton } from './apiSingleton';

class UnifiedEvents {
  static proxyHandlers = {
    get(target, prop, receiver) {
      // Check if the method exists on UnifiedEvents
      if (prop in target) {
        return Reflect.get(target, prop, receiver);
      }

      // Otherwise, select method from apiSingleton.instance.reporting
      if (prop in apiSingleton.instance.reporting) {
        return apiSingleton.instance.reporting[prop];
      }
    },
  };

  reportContentImpression(guideItem) {
    const event = getContentEventData({
      guideItem,
      state: storeSingleton.state,
    });

    apiSingleton.instance.reporting.reportContentImpression(event);
  }

  reportContentClicked(guideItem) {
    const event = getContentEventData({
      guideItem,
      state: storeSingleton.state,
      includeActionType: true,
    });

    apiSingleton.instance.reporting.reportContentClicked(event);
  }

  reportAdEligiblity({ audio = false, video = false }) {
    apiSingleton.instance.reporting.reportAdsVideoAudioRollEligibilityDecided({
      isEligible: false,
      isPlatformEligible: audio,
      adType: adTypes.AD_TYPE_AUDIO,
      adSlot: reportingAdSlotNames.AD_SLOT_PREROLL,
    });
    apiSingleton.instance.reporting.reportAdsVideoAudioRollEligibilityDecided({
      isEligible: false,
      isPlatformEligible: video,
      adType: adTypes.AD_TYPE_VIDEO,
      adSlot: reportingAdSlotNames.AD_SLOT_PREROLL,
    });
  }

  reportPlayerError(context) {
    const {
      playerName = '',
      streamId = '',
      url = '',
      format = '',
      error = {},
      originalErrorMessage = '',
      wasInSteadyState,
    } = context || {};

    apiSingleton.instance.reporting.reportSandbox({
      category: player.category,
      action: player.actions.error,
      props: {
        playerName,
        streamId,
        url,
        format,
        errorData: safeJsonStringify(error.data) || '',
        errorMessage: error.message || originalErrorMessage || 'Unknown error',
        errorType: error.type || 'Unknown',
        isMediaError: error.isMediaError?.toString() || '',
        isNetworkError: error.isNetworkError?.toString() || '',
        wasInSteadyState: wasInSteadyState?.toString() || 'false',
      },
    });
  }

  reportPlayerStopClick() {
    const discordState = selectDiscordState(storeSingleton.state);

    if (!discordState.canControlPlayback) {
      return;
    }

    return apiSingleton.instance.reporting.reportSandbox({
      category: playerClick.category,
      action: playerClick.actions.stop,
    });
  }

  reportPlayerPauseClick() {
    const discordState = selectDiscordState(storeSingleton.state);

    if (!discordState.canControlPlayback) {
      return;
    }

    apiSingleton.instance.reporting.reportSandbox({
      category: playerClick.category,
      action: playerClick.actions.pause,
    });
  }

  reportPageLoadMetrics() {
    if (!selectIsFord(storeSingleton.state)) {
      return;
    }

    const performanceEntries =
      performance?.getEntriesByType?.('navigation')?.[0];
    const loadTime = Math.round(
      performanceEntries?.loadEventEnd - performanceEntries?.startTime,
    );

    if (!loadTime || !performanceEntries) {
      return;
    }

    apiSingleton.instance.reporting.reportSandbox({
      category: app.category,
      action: app.actions.performance,
      props: {
        load: Math.round(
          performanceEntries?.loadEventEnd - performanceEntries?.startTime || 0,
        ).toString(),
      },
    });
  }
}

export const unifiedEvents = new Proxy(
  new UnifiedEvents(),
  UnifiedEvents.proxyHandlers,
);
