import { pick } from '@tunein/web-utils';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mintSingleton } from 'src/client/mint';
import { mintInit } from '../../actions/mint';
import config from '../../config';
import {
  ALLOW_IP_ADDRESS_SHARING,
  VMAP_PREROLL_ENABLED,
} from '../../constants/experiments/ads';
import { defaultLanguageCode } from '../../constants/locale';
import withMintEventDispatcher from '../../decorators/ads/withMintEventDispatcher';
import {
  selectCanShowBasicAdsUnderGdpr,
  selectCcpaData,
  selectGdprApplies,
  selectIsBot,
  selectIsOptedOutOfTargeting,
  selectOneTrustGdprData,
} from '../../selectors/app';
import {
  selectAudioPrerollInterval,
  selectCountryCode,
  selectExperiment,
} from '../../selectors/config';
import { selectSubscriptionStatus } from '../../selectors/me';
import { canShowPersonalizedAds } from '../../utils/ads/canShowPersonalizedAds';
import { getAdsTargeting } from '../../utils/ads/getAdsTargeting';
import assetUrl from '../../utils/assetUrl';
import { isInDiscordIFrame } from '../../utils/discord';
import isServer from '../../utils/isServer';
import isUserInUSOrUSTerritories from '../../utils/isUserInUSOrUSTerritories';
import vars from '../../vars';
import { VIDEO_AD_CONTAINER_ID } from './constants/displayAds';

const mintInitState = [
  'config',
  'options',
  'partnerId',
  'desktopVersion',
  'isFirstVisit',
  'rtid',
  'gdprData',
  'isMobile',
  'ccpaData',
  'volume',
  'isRegisteredUser',
  'subscriptionStatus',
  'ppid',
  'ipAddress',
  'canShowPersonalizedAds',
  'profiles',
  'selectedGuideId',
  'audioPrerollInterval',
  'isVmapPrerollEnabled',
  'isUserInUS',
  'isOptedOutOfTargeting',
];

const dynamicMintState = [
  'config',
  'options',
  'terms',
  'profiles',
  'selectedGuideId',
  'nowPlaying',
  'positionInfo',
  'volume',
  'playerStatus',
  'username',
  'isRegisteredUser',
  'subscriptionStatus',
  'gdprData',
  'ccpaData',
  'canShowPersonalizedAds',
  'ipAddress',
  'ppid',
  'isVmapPrerollEnabled',
  'isOptedOutOfTargeting',
];

class WithAds extends Component {
  static propTypes = {
    routeProps: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    disableAds: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      mintInit: PropTypes.func.isRequired,
    }),
    config: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    terms: PropTypes.object.isRequired,
    isFirstVisit: PropTypes.bool.isRequired,
    gdprData: PropTypes.object.isRequired,
    rtid: PropTypes.string.isRequired,
    partnerId: PropTypes.string.isRequired,
    desktopVersion: PropTypes.string,
    desktopOS: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    ccpaData: PropTypes.object.isRequired,
    profiles: PropTypes.object.isRequired,
    nowPlaying: PropTypes.object.isRequired,
    positionInfo: PropTypes.object.isRequired,
    volume: PropTypes.number.isRequired,
    playerStatus: PropTypes.string.isRequired,
    username: PropTypes.string, // lowercased in mint
    isRegisteredUser: PropTypes.bool,
    subscriptionStatus: PropTypes.string,
    ppid: PropTypes.string,
    ipAddress: PropTypes.string.isRequired,
    canShowPersonalizedAds: PropTypes.bool.isRequired,
    isUserInUS: PropTypes.bool.isRequired,
    isOptedOutOfTargeting: PropTypes.bool.isRequired,
    // From WithMintEventDispatcher decorator
    registerListeners: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    };

    if (props.disableAds || isServer()) {
      return;
    }

    this.#init();
  }

  async #init() {
    const { actions, locale, registerListeners } = this.props;
    const initProps = pick(this.props, mintInitState);

    await mintSingleton.init({
      api: config.api,
      version: config.api.version,
      env: vars.get('TI_ENV'),
      assetLocation: assetUrl(),
      language: (locale || defaultLanguageCode).substring(0, 2),
      videoAdContainerId: VIDEO_AD_CONTAINER_ID,
      adsEnabled: true,
      blankMp3Url: assetUrl('assets/media/blank.mp3'),
      isInDiscordIFrame: isInDiscordIFrame(),
      ...initProps,
    });

    registerListeners?.();

    // Ingest all state after Mint init
    dynamicMintState.forEach((stateName) => {
      mintSingleton.updateState(stateName, this.props[stateName]);
    });

    // Enable further state updates locally via isReady. Lastly, update global state to indicate that Mint is ready.
    // Enabling local state first avoids any potential race conditions between shouldComponentUpdate() being ungated and
    // setting state updates.
    this.setState({ isReady: true }, () => actions.mintInit());
  }

  // We don't want this component to update, since we're just concerned with it's one-time
  // setup. However, we want to send certain state updates to mint
  shouldComponentUpdate(nextProps) {
    if (this.props.disableAds || !this.state.isReady) {
      return false;
    }

    dynamicMintState.forEach((stateName) => {
      if (!isEqual(nextProps[stateName], this.props[stateName])) {
        mintSingleton.updateState(stateName, nextProps[stateName]);
      }
    });

    return false;
  }

  render() {
    // Nothing to render at all.
    return null;
  }
}

export function mapStateToProps(state, ownProps) {
  const { app, config: configStore, player, profiles, me } = state;
  const { guideId } = ownProps?.routeProps?.guideContext || {};
  const isOptedOutOfTargeting = selectIsOptedOutOfTargeting(state);
  const allowIpAddressSharing =
    selectExperiment(state, ALLOW_IP_ADDRESS_SHARING) &&
    !isOptedOutOfTargeting &&
    (!selectGdprApplies(state) || selectCanShowBasicAdsUnderGdpr(state));
  const gdprData = selectOneTrustGdprData(state);
  const ccpaData = selectCcpaData(state);
  const isUserInUS = isUserInUSOrUSTerritories(selectCountryCode(state));

  return {
    selectedGuideId: guideId,
    disableAds: selectIsBot(state),
    isFirstVisit: app.isTuneInFirstVisit,
    gdprData, // update on change, send via init
    ccpaData, // update on change, send via init
    desktopVersion: app.desktopVersion,
    desktopOS: app.desktopOS,
    rtid: app.tuneInUserSerial,
    partnerId: app.partnerId,
    isMobile: app.isMobile,
    config: configStore.ads,
    terms: configStore.terms,
    options: configStore.autoupdate, // update on change, send via init
    isRegisteredUser: me.details.isRegisteredUser, // update on change, send via init
    volume: player.volume, // update on change, send via init
    profiles, // update on change
    nowPlaying: player.nowPlaying, // update on change
    positionInfo: player.positionInfo, // update on change
    playerStatus: player.playerStatus, // update on change
    username: me.details.userName, // update on change
    subscriptionStatus: selectSubscriptionStatus(state), // update on change
    ppid: getAdsTargeting(state).ppid, // update on change, send via init
    ipAddress: allowIpAddressSharing ? app.ipAddress : '', // update on change
    canShowPersonalizedAds: canShowPersonalizedAds(state), // update on change, send via init
    audioPrerollInterval: selectAudioPrerollInterval(state),
    isVmapPrerollEnabled: selectExperiment(state, VMAP_PREROLL_ENABLED), // update on change, send via init
    isUserInUS,
    isOptedOutOfTargeting,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mintInit,
      },
      dispatch,
    ),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withMintEventDispatcher,
)(WithAds);
