import update from 'immutability-helper';
import {
  ALLOW_AUTOPLAY,
  APP_ERROR,
  BRANCH_INITIALIZED,
  BRAZE_INITIALIZED,
  DISALLOW_AUTOPLAY,
  OPEN_ONETRUST_BANNER_ON_INIT,
  SET_BOTTOM_AD_BANNER_HEIGHT,
  SET_BRANCH_SHOWING_STATUS,
  SET_DISCORD_STATE,
  SET_DO_NOT_ASK_TO_DELETE_DEVICE,
  SET_INFO_BANNER_MESSAGE,
  SET_ONETRUST_DATA,
  SET_ONETRUST_GDPR_DATA,
  SET_SHOW_PAIRING_SUCCESS_PAGE,
  SET_TOP_BANNER_HEIGHT,
  UPDATE_BRANCH_LINK,
  UPDATE_BREAKPOINT,
  UPDATE_MW_BYPASS_COOKIE,
} from '../actions/app';
import { FETCH_CATEGORY } from '../actions/browse';
import {
  ADD_ME_DEVICE,
  DELETE_ME_DEVICE,
  FETCH_FAVORITES_FOLDER,
  FETCH_ME,
  FETCH_ME_DEVICES,
  FETCH_ME_WITH_FAVORITES,
  SAVE_ME,
} from '../actions/me';
import { FETCH_MORE_ITEMS } from '../actions/moreItems';
import { GET_PRODUCTS_BY_SKUS } from '../actions/products';
import { FETCH_PROFILE, FETCH_TOPIC_PROFILE } from '../actions/profile';
import { FETCH_PREPOPULATE_SEARCH, FETCH_SEARCH } from '../actions/search';
import {
  CREATE_SETUP_INTENT,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_USER,
  FETCH_COUPON,
  GET_SIGNATURE,
  GET_SUBSCRIPTION,
  LINK_EXISTING_SUBSCRIPTION,
  POST_RECEIPT_TO_REV_CAT,
  RENEW_SUBSCRIPTION,
} from '../actions/subscription';
import {
  FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW,
  RESET_SUBSCRIPTION_FLOW,
} from '../actions/subscriptionFlow';
import { FETCH_USER } from '../actions/users';
import { getDefaultDiscordState } from '../components/discord';
import createMappedReducer from './utils/createMappedReducer';
import { mapActionTypesToFetchingStatusHandlers } from './utils/mapActionTypesToFetchingStatusHandlers';

export function getInitialState() {
  return {
    partnerId: '',
    branchUrl: '',
    isBranchInitialized: false,
    isBrazeInitialized: false,
    isBranchBannerShowing: false,
    userAgent: '',
    isDesktop: false,
    isMobile: false,
    isDiscord: false,
    discord: getDefaultDiscordState(),
    enableBetaFeatures: false,
    enableFavorites: true,
    errors: [],
    oneTrust: {
      gdpr: { isGdprReady: false },
      openSettingsDialogOnInit: false,
      ccpa: {},
    },
    doNotAskToDeleteDevice: false,
    desktopVersion: undefined,
    desktopOS: undefined,
    topBannerHeight: 0,
    bottomAdBannerHeight: 0,
    allowAutoplay: false,
    infoBannerMessage: '',
    debug: { showActionLogs: true },
  };
}

export const app = createMappedReducer(getInitialState(), {
  ...mapActionTypesToFetchingStatusHandlers([
    // used by several states
    FETCH_MORE_ITEMS,

    // user
    FETCH_USER,

    // browse
    FETCH_CATEGORY,

    // profile
    FETCH_PROFILE,
    FETCH_TOPIC_PROFILE,

    // products
    GET_PRODUCTS_BY_SKUS,

    // search
    FETCH_SEARCH,
    FETCH_PREPOPULATE_SEARCH,

    // me
    FETCH_ME,
    FETCH_ME_WITH_FAVORITES,
    SAVE_ME,
    FETCH_ME_DEVICES,
    FETCH_FAVORITES_FOLDER,
    ADD_ME_DEVICE,
    DELETE_ME_DEVICE,

    // subscription
    POST_RECEIPT_TO_REV_CAT,
    LINK_EXISTING_SUBSCRIPTION,
    GET_SIGNATURE,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_USER,
    CREATE_SETUP_INTENT,
    RENEW_SUBSCRIPTION,
    GET_SUBSCRIPTION,
    FETCH_COUPON,

    // subscriptionFlow
    FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW,
    RESET_SUBSCRIPTION_FLOW,
  ]),
  [APP_ERROR]: (state, action) =>
    update(state, {
      errors: {
        $push: [action.payload],
      },
    }),
  [UPDATE_BRANCH_LINK]: (state, action) =>
    update(state, {
      branchUrl: { $set: action.branchUrl },
    }),
  [SET_BRANCH_SHOWING_STATUS]: (state, action) =>
    update(state, {
      isBranchBannerShowing: { $set: action.isShowing },
    }),
  [UPDATE_MW_BYPASS_COOKIE]: (state, action) =>
    update(state, {
      mwLandingBypassCookieVal: { $set: action.value },
    }),
  [SET_SHOW_PAIRING_SUCCESS_PAGE]: (state, action) =>
    update(state, {
      showPairingSuccessPage: { $set: action.shouldShowPairingSuccessPage },
    }),
  [SET_ONETRUST_DATA]: (state, action) =>
    update(state, {
      oneTrust: {
        $merge: action.oneTrustData,
        gdpr: {
          $merge: action.oneTrustData?.gdpr || {},
        },
      },
    }),
  [SET_ONETRUST_GDPR_DATA]: (state, action) =>
    update(state, {
      oneTrust: { gdpr: { $merge: action.gdprData } },
    }),
  [OPEN_ONETRUST_BANNER_ON_INIT]: (state) =>
    update(state, {
      oneTrust: { openSettingsDialogOnInit: { $set: true } },
    }),
  [SET_TOP_BANNER_HEIGHT]: (state, action) =>
    update(state, {
      topBannerHeight: { $set: action.height },
    }),
  [SET_BOTTOM_AD_BANNER_HEIGHT]: (state, action) =>
    update(state, {
      bottomAdBannerHeight: { $set: action.height },
    }),
  [SET_DO_NOT_ASK_TO_DELETE_DEVICE]: (state) =>
    update(state, {
      doNotAskToDeleteDevice: { $set: true },
    }),
  [ALLOW_AUTOPLAY]: (state) =>
    update(state, {
      allowAutoplay: { $set: true },
    }),
  [DISALLOW_AUTOPLAY]: (state) =>
    update(state, {
      allowAutoplay: { $set: false },
    }),
  [UPDATE_BREAKPOINT]: (state, action) =>
    update(state, {
      breakpoint: { $set: action.breakpoint },
    }),
  [BRANCH_INITIALIZED]: (state) =>
    update(state, { isBranchInitialized: { $set: true } }),
  [BRAZE_INITIALIZED]: (state) =>
    update(state, { isBrazeInitialized: { $set: true } }),
  [SET_DISCORD_STATE]: (state, action) =>
    update(state, {
      discord: { $set: action.state },
    }),
  [SET_INFO_BANNER_MESSAGE]: (state, action) =>
    update(state, {
      infoBannerMessage: { $set: action.message },
    }),
});
