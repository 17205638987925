import classNames from 'clsx';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import EventListener from 'react-event-listener';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import { v4 as uuidv4 } from 'uuid';
import { logClientError } from '../../../actions/logging';
import { WINDOW_RESIZE_DEBOUNCE_TIMEOUT } from '../../../constants/breakpoints';
import { ADVERTISEMENT } from '../../../constants/localizations/ads';
import { useAdSettings } from '../../../contexts/AdSettingsContext';
import { useForceRerender } from '../../../hooks/useForceRerender';
import useOnMount from '../../../hooks/useOnMount';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { isWithinRange } from '../../../utils/breakpoints';
import keyMirror from '../../../utils/keyMirror';
import DisplayAd from '../../ads/DisplayAd';
import css from './ad-cell.module.scss';

const deviceTypes = keyMirror('mobile', 'desktop');
const supportedAdSizesByDeviceType = {
  [deviceTypes.mobile]: {
    '320x50': true,
    '300x250': true,
  },
  [deviceTypes.desktop]: {
    '728x90': true,
  },
};

export const AdCell = memo(
  ({
    guideItem,
    matchUrl,

    // mapStateToProps
    loadedDisplaySlotId,
    isMobile,

    // mapDispatchToProps
    actions,
  }) => {
    const [isMounted, setIsMounted] = useState(false);
    const { getLocalizedText } = useContext(LocationAndLocalizationContext);
    const { adType: dimensions = '' } = get(guideItem, 'presentation', {});
    const deviceType = isMobile ? deviceTypes.mobile : deviceTypes.desktop;
    const { isProfilePage, isAdEligible } = useAdSettings(
      actions.logClientError,
    );
    const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);
    const checkAdCompatibility = () =>
      isMounted &&
      isAdEligible &&
      !!supportedAdSizesByDeviceType[deviceType][dimensions] &&
      (isMobile ||
        // 728x90 view model ads overflow the ad container in the following scenarios:
        //  - breakpoints below 728px
        //  - between 1192px and 1266px
        //      (The right side pane renders, shrinking the content area width)
        //  - between 768px and 1192px
        //      (Profile pages only. The sidebar upsell renders, shrinking the content area width.)
        // TODO: the lower boundary is capped at 768px until we update the ad config to support
        //  728x90 ads on desktop on the "SMALL" breakpoint
        (!isProfilePage && isWithinRange(768, 1192)) ||
        isWithinRange(1266));
    const [hasMediaAdLoaded, setHasMediaAdLoaded] = useState(false);
    const slotName = `view_model_ads${isMobile ? '_mobile' : ''}`;
    // Using UUID to avoid conflicts between AdCells on the page and the NP dialog, which might arise
    // if we use a more naive approach (e.g., a container index inherited from the parent component)
    const uniqueSlotIdSerial = useRef(uuidv4());
    const slotId = `${slotName}_${uniqueSlotIdSerial.current}`;
    const [preloadWidth, preloadHeight] = dimensions.split('x');
    const adStyles = !hasMediaAdLoaded
      ? { width: `${preloadWidth}px`, height: `${preloadHeight}px` }
      : {};
    const forceRerender = useForceRerender();

    function onAdDeregistration() {
      setHasMediaAdLoaded(false);
    }

    useEffect(() => {
      if (loadedDisplaySlotId === slotId) {
        setHasMediaAdLoaded(true);
      }
    }, [loadedDisplaySlotId, slotId]);

    useOnMount(() => {
      setIsMounted(true);
    });

    return (
      <EventListener
        target="window"
        onResize={debounce(forceRerender, WINDOW_RESIZE_DEBOUNCE_TIMEOUT)}
      >
        {checkAdCompatibility() ? (
          <div data-testid="viewModelAd" className={css.container}>
            <div className={css.containerTitle}>
              <span>{getLocalizedText(ADVERTISEMENT)}</span>
            </div>
            <div
              className={classNames({ [css.ad]: !hasMediaAdLoaded })}
              style={adStyles}
            >
              <DisplayAd
                dimensions={dimensions}
                matchUrl={matchUrl}
                handleAdDeregistration={onAdDeregistration}
                {...getDisplayAdAttributes(
                  gptSlotNames[slotName],
                  isFreestarEnabled,
                  isFreestarEnabled ? `tunein_${slotId}` : slotId,
                )}
              />
            </div>
          </div>
        ) : null}
      </EventListener>
    );
  },
);

AdCell.propTypes = {
  guideItem: PropTypes.object.isRequired,
  matchUrl: PropTypes.string.isRequired,

  // mapStateToProps
  loadedDisplaySlotId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,

  // mapDispatchToProps
  actions: PropTypes.shape({
    logClientError: PropTypes.func.isRequired,
  }),
};

export function mapStateToProps(state) {
  return {
    loadedDisplaySlotId: get(state, 'mint.loadedDisplaySlotId', ''),
    isMobile: state.app.isMobile,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: { logClientError: bindActionCreators(logClientError, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdCell);
