import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mintSingleton } from '../../../client/mint';
import { logCategoryActionLabel } from '../../actions/logging';
import { MINT_CATEGORY_ID } from '../../constants/ads/location';
import webCategory from '../../constants/analytics/categoryActionLabel/web';
import withAdRegistration from '../../decorators/ads/withAdRegistration';
import withDisplayAdWrapper from '../../decorators/ads/withDisplayAdWrapper';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { isCategory, isProfile } from '../../utils/guideItemTypes';

const styles = {
  adContainer: {
    margin: '0',
    textAlign: 'center',
    pointerEvents: 'auto',
    // pointer-events:auto ensures the user can interact with the content of the ad slot,
    // even if the container DisplayAdSlot is rendered in has pointer-events:none
  },
  link: {
    display: 'block',
    cursor: 'default',
    lineHeight: 0,
    opacity: 0,
  },
};

// disable linting because this needs to be a class or it blows up
// in withAdRegistration call.
// eslint-disable-next-line react/prefer-stateless-function
class DisplayAdSlot extends Component {
  static propTypes = {
    // withAdRegistration decorator
    slotName: PropTypes.string.isRequired,

    id: PropTypes.string.isRequired,
    guideId: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    userAgent: PropTypes.string,
    loggingActions: PropTypes.shape({
      logCategoryActionLabel: PropTypes.func.isRequired,
    }).isRequired,
    placementAttributes: PropTypes.object,
  };

  static contextType = LocationAndLocalizationContext;

  componentDidMount() {
    this.updateMintCategoryId();
  }

  componentDidUpdate(prevProps) {
    const { guideId } = this.props;
    if (guideId !== prevProps.guideId) {
      this.updateMintCategoryId();
    }
  }

  componentWillUnmount() {
    mintSingleton.updateState('categoryId', null);
  }

  updateMintCategoryId() {
    const { guideId } = this.props;

    if (isCategory(guideId)) {
      mintSingleton.updateState('categoryId', guideId);
      return;
    }

    const mintCategoryId = get(
      this.context,
      ['location', 'state', MINT_CATEGORY_ID],
      '',
    );
    const canSetCategory = mintCategoryId && isCategory(mintCategoryId);

    if (isProfile(guideId) && canSetCategory) {
      mintSingleton.updateState('categoryId', mintCategoryId);
      return;
    }

    mintSingleton.updateState('categoryId', null);
  }

  disableAdsAndLog = () => {
    const { loggingActions, userAgent } = this.props;
    mintSingleton.instance?.disableAds();
    loggingActions.logCategoryActionLabel({
      category: webCategory.category,
      action: webCategory.actions.honeypot,
      label: userAgent,
    });
  };

  render() {
    const { style, id, className, placementAttributes } = this.props;

    return (
      <div className={className} style={{ ...styles.adContainer, ...style }}>
        <div id={id} {...placementAttributes} />
        {/* This is a honeypot to catch click bots */}
        <a
          id="ad_unit"
          data-testid="adUnit"
          onClick={this.disableAdsAndLog}
          style={{ ...styles.link }}
          aria-hidden="true"
        >
          --
        </a>
      </div>
    );
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    loggingActions: bindActionCreators({ logCategoryActionLabel }, dispatch),
  };
}

export function mapStateToProps(state) {
  return {
    userAgent: get(state, 'app.userAgent'),
  };
}

export default flow(
  withAdRegistration({
    autoPlay: true,
    refreshOnPageChange: true,
  }),
  withDisplayAdWrapper,
  connect(mapStateToProps, mapDispatchToProps),
)(DisplayAdSlot);
